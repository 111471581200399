export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [2];

export const dictionary = {
		"/": [~3],
		"/(admin)/admin": [~4,[2]],
		"/(admin)/admin/gift-cards": [~5,[2]],
		"/(admin)/admin/gift-cards/[id]": [~6,[2]],
		"/(admin)/admin/orders": [~7,[2]],
		"/(admin)/admin/orders/[id]": [~8,[2]],
		"/(admin)/admin/stores": [~9,[2]],
		"/(admin)/admin/stores/reports": [~11,[2]],
		"/(admin)/admin/stores/[id]": [~10,[2]],
		"/(auth)/login": [~12]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';